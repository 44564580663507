'use client';

import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

import { Button } from '~/components/core/Button';
import { EmptyState } from '~/components/core/EmptyState';
import { Icon, IconsName } from '~/components/core/Icon';

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const [currentLang, setCurrentLang] = useState('de');

  useEffect(() => {
    setCurrentLang(document.location.pathname.substring(1, 3));
    Sentry.captureException(error);
  }, [error]);

  const handleRefresh = () => {
    window.location.reload();
  };

  const errorMessages = {
    de: {
      title: 'Es sieht so aus, als wäre etwas schief gelaufen...',
      message:
        'Sollte dies weiterhin der Fall sein, wenden Sie sich bitte an den Support.',
      cta: 'Seite neu laden',
    },
    en: {
      title: 'It looks like something went wrong...',
      message: 'If this error persists, please contact us.',
      cta: 'Refresh page',
    },
    fr: {
      title: `Il semblerait que quelque chose n'ait pas fonctionné...`,
      message: 'Si cette erreur persiste, veuillez nous contacter.',
      cta: `Retour à la page d'accueil`,
    },
  };

  return (
    <html>
      <body>
        {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
        <EmptyState
          icon={<Icon name={IconsName.EmptyNotFound} color="none" size={80} />}
          // @ts-ignore
          title={errorMessages[currentLang].title}
          // @ts-ignore
          description={errorMessages[currentLang].message}
          action={
            <Button mode="transparent" withBorder onClick={handleRefresh}>
              {/* @ts-ignore */}
              {errorMessages[currentLang].cta}
            </Button>
          }
        />
      </body>
    </html>
  );
}
