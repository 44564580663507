import { Box, Heading, VStack } from '@chakra-ui/react';

type Props = {
  title: React.ReactNode;
  description?: React.ReactNode;
  icon: React.ReactNode;
  action?: React.ReactNode;
};

export function EmptyState({ title, description, icon, action = null }: Props) {
  return (
    <VStack>
      <Box mb={6}>{icon}</Box>
      <VStack spacing={2}>
        <Heading fontSize={'1.75rem'} textAlign="center">
          {title}
        </Heading>
        {description && <Box>{description}</Box>}
      </VStack>
      {action && <Box mt={6}>{action}</Box>}
    </VStack>
  );
}
